import { generateSlugFromID } from 'utils/urls/categoriesPathSegment';
import { List, Typography } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styles/styled-components';
import { Category, NotificationMessage, NotificationMessageNavigationType, ReadStatus } from 'types/schema';
import { getDateText } from 'utils/dateTimeUtils';

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding-right: 20px;
  cursor: pointer;
  gap: 20px;
`;

const ListInfo = styled.div`
  display: grid;
  flex-grow: 1;
`;

const DateText = styled.span`
  padding-left: 20px;
  width: 128px;
`;

const RedDotBadge = styled.div`
  width: 10px;
  height: 10px;
  background: #ff4852;
  border-radius: 50%;
  border: 1px solid #ffffff;
  margin-left: 10px;
`;

interface NotificationItemProps extends RouteComponentProps {
  notificationMessage: NotificationMessage;
  buyerId: string;
  storeId: string;
  markNotificationAsRead: (messageId: string) => void;
  categories: Category[];
  lang: string;
}

const NotificationItem: FC<NotificationItemProps> = (props) => {
  const { history, notificationMessage, buyerId, storeId, markNotificationAsRead, categories, lang } = props;
  const isUnread = notificationMessage.readStatus === ReadStatus.NotRead;

  const onClickNotification = useCallback(
    (id: string) => {
      if (isUnread) {
        markNotificationAsRead(id);
      }
      const messageData = notificationMessage?.data;
      if (!messageData) return;

      if (messageData.navType && messageData.navValue) {
        switch (messageData.navType) {
          case NotificationMessageNavigationType.Category:
            const arrayCategory = messageData.navValue?.split('/'); // NavValue có dạng Category/SubCategory

            const category = categories.find((cat: Category) => cat.name === arrayCategory[0]);
            if (!category) history.push(`/market`);
            else {
              if (arrayCategory.length === 1) history.push(`/${generateSlugFromID(category, lang)}`);
              else {
                const subCategory = category.children?.find((subCat: Category) => subCat.name === messageData.navValue);
                if (!subCategory) history.push(`/${generateSlugFromID(category, lang)}`);
                else history.push(`/${generateSlugFromID(subCategory, lang)}`);
              }
            }
            break;
          case NotificationMessageNavigationType.Product:
            history.push(`/products/${messageData.navValue}`);
            break;
          case NotificationMessageNavigationType.Tag:
            history.push(`/market?tags=${messageData.navValue}`);
            break;
          case NotificationMessageNavigationType.Url:
            window.open(`${messageData.navValue}`, '_blank');
            break;
          default:
            break;
        }
        return;
      }

      if ('orderId' in messageData) {
        history.push(`/orders/${messageData.orderId}`);
      }
    },
    [buyerId, storeId, isUnread, markNotificationAsRead],
  );

  return (
    <List.Item onClick={() => onClickNotification(notificationMessage.id)}>
      <ListItem>
        <DateText>{getDateText(notificationMessage.createdAt)}</DateText>
        <ListInfo>
          <Typography.Text>{notificationMessage.title}</Typography.Text>
          <Typography.Text type="secondary">{notificationMessage.content}</Typography.Text>
        </ListInfo>
        {isUnread && <RedDotBadge />}
      </ListItem>
    </List.Item>
  );
};

export default withRouter(memo(NotificationItem));
