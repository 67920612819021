import PageHeader from 'components/PageHeader/PageHeader';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import translations from 'translations';
import { Buyer, Category, NotificationMessage, Pagination, Store } from 'types/schema';

import media from 'utils/mediaStyle';
import messages from './messages';
import { List, Pagination as APagination, Spin } from 'antd';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { connect } from 'react-redux';
import { markNotificationAsRead, notifications } from 'containers/MainLayout/actions';
import {
  selectBuyer,
  selectCategories,
  selectLang,
  selectNotificationData,
  selectStore,
} from 'containers/MainLayout/selectors';
import { selectIsTreatmentReady } from 'split/selectors';
import { NotificationsData } from 'containers/MainLayout/types';
import NotificationItem from 'components/NotificationItem';

interface IStateProps {
  isTreatmentReady: boolean;
  notifications: NotificationsData;
  buyer: Buyer;
  store: Store;
  categories: Category[];
  lang?: string;
}

interface IDispatchProps {
  getNotfications: (params: Pagination) => void;
  markNotificationAsRead: (messageId: string) => void;
}

type Props = IStateProps & IDispatchProps;

const Container = styled.div`
  margin-top: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  @media (max-width: 1024px) {
    margin-top: 6px;
  }
`;

const PageContainer = styled(Container)`
  padding: 24px;
  ${media.md`
    padding: 24px;
  `};
`;

const PaginationContainer = styled.div`
  margin: 24px 0px;
  text-align: right;
`;

const NotificationPage: FC<Props> = (props) => {
  const { getNotfications, notifications, markNotificationAsRead, buyer, store, categories, lang } = props;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const onChangePagination = useCallback((page: number) => {
    setCurrentPage(page);
    getNotfications({ page: page - 1, size: 20 });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getNotfications({ page: 0, size: 20 });
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>{translations(messages.notification)}</title>
        <meta name="description" content={`${translations(messages.notification)}`} />
      </Helmet>
      <PageHeader>{translations(messages.notification)}</PageHeader>
      <Container>
        <Spin spinning={notifications.loading}>
          <List
            dataSource={notifications?.data || []}
            renderItem={(item: NotificationMessage) => {
              return (
                <NotificationItem
                  notificationMessage={item}
                  buyerId={buyer?.id}
                  storeId={store?.id}
                  markNotificationAsRead={markNotificationAsRead}
                  categories={categories}
                  lang={lang || 'vi'}
                />
              );
            }}
          />
        </Spin>
      </Container>
      <PaginationContainer>
        <APagination
          onChange={onChangePagination}
          total={notifications.totalResults}
          current={currentPage}
          pageSize={20}
          showSizeChanger={false}
        />
      </PaginationContainer>
    </PageContainer>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  isTreatmentReady: selectIsTreatmentReady(),
  notifications: selectNotificationData(),
  buyer: selectBuyer(),
  store: selectStore(),
  categories: selectCategories(),
  lang: selectLang(),
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  getNotfications: (payload: Pagination) => dispatch(notifications(payload)),
  markNotificationAsRead: (messageId: string) => dispatch(markNotificationAsRead({ messageId: messageId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
